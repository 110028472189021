<template>
  <BaseLayout>
    <template #header>
      <h2>Daftar Rumah Sakit</h2>
      <p class="hp-p1-body mb-0 text-black">
        Semua ({{ table.total }})
      </p>
      <div class="d-flex justify-content-between">
        <b-button
          variant="primary-2"
          to="/dashboards/add-rs"
        >
          Tambah Daftar Rumah Sakit
        </b-button>
        <div class="row no-gutters w-15">
          <b-input-group>
            <b-input-group-prepend>
              <b-button
                variant="outline-black-50"
                class="border-right-0 pr-0 pl-2"
              >
                <i class="ri-search-line remix-icon text-primary" />
              </b-button>
            </b-input-group-prepend>
            <b-form-input
              v-model="table.name"
              type="search"
              placeholder="Search"
              class="border-left-0"
              @input="debounceSearch"
            />
          </b-input-group>
        </div>
      </div>
    </template>
    <template #content>
      <b-table
        style="overflow: auto; white-space: nowrap"
        :items="table.items"
        :fields="fields"
        :busy.sync="table.loading"
        responsive="sm"
        show-empty
      >
        <template #table-busy>
          <div class="hp-p1-body text-center my-2">
            <b-spinner class="align-middle mr-8" />
            <strong>Memuat...</strong>
          </div>
        </template>
        <template #empty>
          <div class="hp-p1-body text-center my-2">
            <strong>Tidak Ada Data</strong>
          </div>
        </template>
        <template v-slot:cell(action)="row">
          <b-button
            class="mx-1"
            variant="danger-2"
            @click="deleteHospital(row.item.id)"
          >
            <i class="ri-delete-bin-6-line mr-1" />
            Hapus
          </b-button>
          <router-link :to="`/dashboards/detail-rs/${row.item.id}`">
            <b-button variant="info">
              <i class="ri-edit-box-line mr-2" /> Detail
            </b-button>
          </router-link>
          <router-link :to="`/dashboards/update-rs/${row.item.id}`">
            <b-button class="mx-1" variant="info-1">
              <i class="ri-edit-box-line mr-1" />
              Edit
            </b-button>
          </router-link>
        </template>
      </b-table>
      <b-pagination
        v-model="table.currentPage"
        class="mb-3"
        align="right"
        :per-page="table.perPage"
        :total-rows="table.total"
      />

      <BaseModal>
        <template #header>
          <h5 class="mb-0">
            Konfirmasi Hapus Data
          </h5>
        </template>
        <p class="hp-p1-body mb-0">
          Apakah anda yakin akan menghapus Rumah Sakit Ini ?
        </p>
        <div class="d-flex justify-content-end flex-wrap mt-32">
          <b-button
            variant="text"
            @click="$bvModal.hide('base-modal')"
          >
            Close
          </b-button>

          <b-button
            class="ml-16"
            variant="primary"
            @click="deleteItem"
          >
            Hapus Data Rumah Sakit
          </b-button>
        </div>
      </BaseModal>

    </template>
  </BaseLayout>
</template>
<script>
import {
  BButton, BInputGroup, BInputGroupPrepend, BFormInput, BPagination, BSpinner, BTable,
} from 'bootstrap-vue'
import tableDataMixins from '../../../../mixins/tableDataMixins'
import manageHospitalAPI from '../../../../api/managehospital/manageHospitalAPI'

export default {
  name: 'Hospitals',
  components: {
    BButton, BInputGroup, BInputGroupPrepend, BFormInput, BPagination, BSpinner, BTable,
  },
  mixins: [tableDataMixins],
  data() {
    return {
      isBusy: false,
      itemsToDelete: null,
      fields: [
        {
          key: 'id',
          label: 'Id',
        },
        {
          key: 'name',
          label: 'Nama Rumah Sakit',
        },
        {
          key: 'phone',
          label: 'Telepon',
        },
        {
          key: 'address',
          label: 'Alamat',
        },
        {
          key: 'created_at',
          label: 'Tanggal Dibuat',
        },
        {
          key: 'action',
          label: 'Aksi',
        },
      ],
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      this.table.loading = true

      const params = {
        page: this.table.currentPage,
        per_page: this.table.perPage,
        search: this.table.name,
      }

      await manageHospitalAPI.listDroppingRS({ params }).then(response => {
        this.table.items = response.data.data.data
        this.table.total = response.data.data.total
      }).catch(e => {
        console.error(e)
      }).finally(() => {
        this.table.loading = false
      })
    },

    onPageChange(newPage) {
      this.table.currentPage = newPage
      this.fetchData()
    },
    debounceSearch() {
      clearTimeout(this.table.debounceTimer)

      this.table.debounceSearch = setTimeout(() => {
        this.onChangeSearchTable()
      }, 500)
    },
    onChangeSearchTable() {
      this.fetchData()
    },
    deleteHospital(id) {
      this.itemsToDelete = id
      this.$bvModal.show('base-modal')
    },
    async deleteItem() {
      if (this.itemsToDelete) {
        this.$bvModal.hide('base-modal')

        const payload = {
          id: this.itemsToDelete,
        }

        await manageHospitalAPI.deleteRSDropping(payload)
          .then(() => {
            this.fetchData()

            this.$bvToast.toast('Informasi Rumah Sakit berhasil dihapus', {
              title: 'Sukses',
              variant: 'success',
              solid: true,
            })
          })
          .catch(err => {
            this.$bvToast.toast(err, {
              title: 'Data Gagal Dihapus',
              variant: 'danger',
              solid: true,
            })
          })
          .finally(() => {
            this.itemsToDelete = null
          })
      }
    },
  },
}
</script>
